@import '../../../../../assets/scss/colors/colors.scss';

.filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20%;
  color: $maroon_600;
  font-weight: bold;
  height: 4rem;
  column-gap: 20px;
}

.footer-section {
  background: url("../../../../ui-kit/Icons/svg/FooterBanner.svg");
  background-size: contain;
  background-position: bottom center;
  // position: fixed;
  // left: 0;
  // bottom: -5px;
  min-width: 100%;
  height: 4rem;
  background-repeat: no-repeat;
  @media (min-width: 808px) {
    background-size: cover;
    background-position: top center;
  }
}

.checkbox-container {
  display: grid;
  justify-items: center;
  cursor: pointer;
  span {
    font-size: 8px;
  }
  // margin-bottom: 1.2rem;
}
