@import "../../../assets/scss/colors/colors.scss";

.nav-item {
  padding: 0.5rem;
  min-width: 5rem;
  max-width: 9rem;
  width: 100%;
  &.active {
      background-color: $maroon_500;
      border-radius: 0.75rem 0.75rem 0 0;
  }
}

.tab-btn {
  background-image: linear-gradient(
    180deg,
    $orange_250 -9.52%,
    $yellow_100 22.07%,
    $yellow_150 71.96%,
    $yellow_200 100%
  );
  line-height: 14px;
}

.tab-btn-active {
  background-image: linear-gradient(
    180deg,
    $orange_300 -2.38%,
    $yellow_600 27.15%,
    $yellow_700 73.79%,
    $orange_350 100%
  );
}

.btn-check:focus+.btn, .btn:focus {
  box-shadow: none;
}

.tab-button {
  display: grid;
  width: 100%;
}

.tab-title {
  font-size: 0.8rem;
  font-weight: 700;
}

.tab-subtitle {
  font-size: 0.8rem;
  font-weight: 500;
}
