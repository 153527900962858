@import '../../../../assets/scss/colors/colors.scss';

.tournament-info {
  color: $white;
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
