// Common colors
$white: #ffffff;
$black: #000000;
$red: #E20000;

// Orange Color Shades
$orange_100: #fecf60;
$orange_200: #fdb951;
$orange_250: #f5ae20;
$orange_300: #feae00;
$orange_350: #feb300;
$orange_400: #d99a30;
$orange_450: #ca7917;
$orange_500: #f77201;
$orange_600: #ce4e00;
$orange_700: #cb4d00;
$orange_800: #b03600;
$orange_900: #a32109;
$orange_950: #931d01;

// Yellow Color Shades
$yellow_100: #ffffae;
$yellow_150: #fdea88;
$yellow_200: #fff683;
$yellow_250: #f2da60;
$yellow_300: #ffdd7c;
$yellow_600: #fffb00;
$yellow_700: #ffff02;

// Maroon Color Shades
$maroon_400: #6F0100;
$maroon_500: #500802;
$maroon_550: #5e0606;
$maroon_600: #330000;
$maroon_650: rgb(3 3 3 / 35%);
