@import '../../../../assets/scss/colors/colors.scss';

.user-info-section {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.info-label-style {
  font-size: 0.8rem;
  font-weight: 700;
}

.info-text-color {
  color: #FFCC00;
}
