/* ARIAL CONDENSED BOLD REGULAR */
@font-face {
  font-family: 'Arial-Condensed-Bold';
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Arial_Condensed_Bold_Regular.ttf');
}

/* ARIAL CONDENSED BOLD REGULAR */
@font-face {
  font-family: 'Montara';
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Montara_Bold_Gothic.otf');
}

/* ARIAL CONDENSED BOLD REGULAR */
@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-display: swap;
  src: url('../fonts/arial.ttf');
}

@import "./colors/colors.scss";
@import "./fonts-utils/index.scss";
@import "./margin-utils/index.scss";
@import "./padding-utils/index.scss";

$font-family_1: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-image: radial-gradient(89.79% 81.99% at 86.95% 73.16%, $red 0%, $maroon_400 100%);
  color: $yellow_300;
  height: 100dvh;
  font-family: 'Arial', sans-serif;

  @media screen and (orientation:portrait) {
    height: 100dvw;
    width: 100dvh;
    overflow-x: auto;
    position: absolute;
    top: 100%;
    right: 0;
    transform-origin: 100% 0vh 0;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
}

html {
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  margin: 0;
}

code {
  font-family: $font-family_1;
}

#root {
  height: 100%;
}

.page-loader {
  position: fixed;
  top: calc(50% - 45px/2);
  left: calc(50% - 40px/2);
  z-index: 1;
}

.fs-1-25 {
  font-size: 1.25rem;
}

.form-range {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.arial-condensed-bold {
  font-family: 'Arial-Condensed-Bold';
}

.arial {
  font-family: 'Arial';
}

.montara {
  font-family: 'Montara';
}
