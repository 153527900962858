// Unit Calculation 1 rem = 16 px

// ==============Font Sizes Classes (Start)==============
.fs-0x5 {
  font-size: 0.5rem !important;
}

.fs-0x6 {
  font-size: 0.6rem !important;
}

.fs-0x7 {
  font-size: 0.7rem !important;
}

.fs-0x8 {
  font-size: 0.8rem !important;
}

.fs-0x9 {
  font-size: 0.9rem !important;
}

.fs-1 {
  font-size: 1rem !important;
}

.fs-1x1 {
  font-size: 1.1rem !important;
}

.fs-1x2 {
  font-size: 1.2rem !important;
}

.fs-1x3 {
  font-size: 1.3rem !important;
}

.fs-1x375 {
  font-size: 1.375rem !important;
}

.fs-1x4 {
  font-size: 1.4rem !important;
}

.fs-1x5 {
  font-size: 1.4rem !important;
}

.fs-1x6 {
  font-size: 1.6rem !important;
}

.fs-1x7 {
  font-size: 1.7rem !important;
}

.fs-1x8 {
  font-size: 1.8rem !important;
}

.fs-1x9 {
  font-size: 1.9rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}
// ==============Font Sizes Classes (End)==============

// ==============Font Weight Classes (Start)==============
.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}
// ==============Font Weight Classes (End)==============

// ==============Font Alignment Classes (Start)==============
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
// ==============Font Alignment Classes (End)==============

// ==============Float Alignment Classes (Start)==============
.float-none {
  float: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}
// ==============Float Alignment Classes (End)==============
